import { css } from "@emotion/css";
import { FC, useRef } from "react";
import useOutsideClick from "../../../utils/hooks/useOutsideClick";

export type TabbarHandlers = {
  home: () => void;
  action: () => void;
  mission: () => void;
  electricity: () => void;
};

export type Props = {
  activeTab?: "ホーム" | "アクション" | "ミッション" | "電気使用量";
  onClick: TabbarHandlers;
};

/**
 * タブバー全体
 * @param param0
 * @returns
 */
export const Tabbar: FC<Props> = ({ activeTab, onClick }) => {
  return (
    <nav className={activeTab === "ホーム" ? tabbarHome : tabbar}>
      <HomeTab isActive={activeTab === "ホーム"} onClick={onClick.home} />
      <ActionTab
        isActive={activeTab === "アクション"}
        onClick={onClick.action}
      />
      <MissionTab
        isActive={activeTab === "ミッション"}
        onClick={onClick.mission}
      />
      <ElectricityTab
        isActive={activeTab === "電気使用量"}
        onClick={onClick.electricity}
      />
    </nav>
  );
};

export const tabbarHeight = 54;

const fadeInAnime = css`
  animation-name: fade-in-modal;
  animation-duration: 350ms;
  @keyframes fade-in-modal {
    0% {
      opacity: 0%;
    }
    100% {
    }
  }
`;

const tabbar = css`
  position: fixed;
  left: 0;
  bottom: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 0px;

  /* TODO iphoneSE以外のケース考慮した場合、width100%がよいかもしれない */
  width: 100vw;
  height: ${tabbarHeight}px;

  background: #f2f2f2;
  border-top: 1px solid #e1e1e1;
`;
const tabbarHome = css`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 0px;
  width: 100vw;
  background: #f2f2f2;
  border-top: 1px solid #e1e1e1;
`;
const ICON_DIR = "/img/greenscore/tabbar";
/**
 * タブバーのホーム
 * @param param0
 * @returns
 */
const HomeTab: FC<{ isActive: boolean; onClick: () => void }> = ({
  isActive,
  onClick,
}) => (
  <TabbarItem
    name="ホーム"
    iconUrl={`${ICON_DIR}${isActive ? "/active/" : "/"}home.svg`}
    isActive={isActive}
    onClick={onClick}
  />
);
/**
 * タブバーのアクション
 * @param param0
 * @returns
 */
const ActionTab: FC<{ isActive: boolean; onClick: () => void }> = ({
  isActive,
  onClick,
}) => (
  <TabbarItem
    name="アクション"
    iconUrl={`${ICON_DIR}${isActive ? "/active/" : "/"}action.svg`}
    isActive={isActive}
    onClick={onClick}
  />
);
/**
 * タブバーのミッション
 * @param param0
 * @returns
 */
const MissionTab: FC<{ isActive: boolean; onClick: () => void }> = ({
  isActive,
  onClick,
}) => (
  <TabbarItem
    name="ミッション"
    iconUrl={`${ICON_DIR}${isActive ? "/active/" : "/"}mission.svg`}
    isActive={isActive}
    onClick={onClick}
  />
);
/**
 * タブバーの電気使用量
 * @param param0
 * @returns
 */
const ElectricityTab: FC<{ isActive: boolean; onClick: () => void }> = ({
  isActive,
  onClick,
}) => (
  <TabbarItem
    name="電気使用量"
    iconUrl={`${ICON_DIR}${isActive ? "/active/" : "/"}electricity.svg`}
    isActive={isActive}
    onClick={onClick}
  />
);

type TabbarItemProps = {
  name: string;
  iconUrl: string;
  isActive: boolean;
  onClick: () => void;
};

/**
 * タブバーの要素
 * @param param0
 * @returns
 */
const TabbarItem: FC<TabbarItemProps> = ({
  name,
  iconUrl,
  isActive,
  onClick,
}) => {
  return (
    <div
      className={isActive ? tabbarItemBlockActive : tabbarItemBlock}
      onClick={() => {
        !isActive && onClick();
      }}
    >
      <div className={tabbarIcon(iconUrl)}></div>
      <p className={isActive ? tabbarNameActive : tabbarName}>{name}</p>
    </div>
  );
};

const tabbarItemBlock = css`
  width: 93.75px;
  height: 54px;
  padding-top: 7px;
  cursor: pointer;
`;
const tabbarItemBlockActive = css`
  ${tabbarItemBlock}
  box-sizing: border-box;
  border-top: 1px solid #1e5343;
  cursor: initial;
`;

const tabbarIcon = (iconUrl: string) => css`
  width: 24px;
  height: 24px;
  margin-left: auto;
  margin-right: auto;
  background-image: url(${iconUrl});
`;
const tabbarName = css`
  width: 100%;
  margin-top: 4px;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 15px;
  text-align: center;
  color: rgba(72, 72, 65, 0.5);
`;
const tabbarNameActive = css`
  ${tabbarName}
  color: #1E5343;
`;

type PropsForHomeModal = { onClickClose: () => void };

export const TabbarActionHighlightLayer: FC<PropsForHomeModal> = ({
  onClickClose,
}) => {
  const ref = useRef(null);
  useOutsideClick(ref, () => onClickClose());
  return (
    <div className={blankTabbarFrame}>
      <div className={noActionTooltip}>
        <div className={noActionText} ref={ref}>
          まだアクションがありません、
          <br />
          アクションを追加しよう！
        </div>
        <div
          className={noActionTooltipClose}
          onClick={() => onClickClose()}
        ></div>
      </div>
      <div className={highlightActionIcon}></div>
      <p className={highlightTabbarName}>アクション</p>
    </div>
  );
};

const blankTabbarFrame = css`
  ${fadeInAnime};
  position: absolute;
  z-index: 60;
  left: 0;
  bottom: 0;
  width: 100vw;
  height: ${tabbarHeight}px;
`;

const noActionTooltip = css`
  position: absolute;
  width: calc(254px + 10px + 10px);
  height: calc(81px + 9px + 9px);
  top: -101px;
  left: 50%;
  transform: translateX(-66%);

  background-image: url(${`${ICON_DIR}/highlight/tooltip.svg`});
  padding-top: calc(10px);
  padding-left: calc(0px + 10px);
  padding-right: calc(40px + 10px);
  color: #1e5343;
`;

const noActionText = css`
  width: 214px;
  height: 64px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  padding-top: 12px;
  padding-left: 12px;
`;

const noActionTooltipClose = css`
  position: absolute;
  top: calc(16px + 10px);
  left: calc(210px + 10px);
  width: 32px;
  height: 32px;
  background-image: url(${`${ICON_DIR}/highlight/tooltip-close.svg`});
`;

const highlightActionIcon = css`
  ${tabbarIcon(`${ICON_DIR}/highlight/action.svg`)}
  position: absolute;
  top: 7px;
  left: 50%;
  transform: translateX(-245%);
`;
const highlightTabbarName = css`
  ${tabbarName}
  width: 125px;
  color: #ffffff;
  position: absolute;
  top: 31px;
  left: 50%;
  transform: translateX(-87%);
`;
