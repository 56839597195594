import { css } from "@emotion/css";

export const container = css`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100vw;
  height: 100vh;
  background-color: #f2f2f2;
`;

export const backgroundGray = css`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 375px;
  height: 667px;
  background-color: #f2f2f2;
`;

export const backgroundColor = css`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100vw;
  height: 100vh;
  background-image: url("/img/templates/home/background.png");
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
`;

export const loadingCircle = css`
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;
