/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @next/next/no-img-element */
import { SwitchLayoutGroupContext } from "framer-motion";
import React from "react";
import * as style from "./style";

interface propsType {
  isColor?: boolean;
}

const Loading = (props: propsType) => {
  const { isColor } = props;

  return (
    <div className={style.container}>
      <div className={isColor ? style.backgroundColor : style.backgroundGray}
       style={{
          display:"flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100vw",
          height: "100vh"
        }}>
        <img
          className={style.loadingCircle}
          src="\img\atoms\loadingCircle\24_progress.svg"
        />
      </div>
    </div>
  );
};

export default Loading;
