import { useEffect, useState } from "react";
import { ErrorState, setError } from "../../services/error/slice";
import { useAppDispatch } from "../../services/store";

export const useErrorSetting = (props?: ErrorState) => {
  const dispatch = useAppDispatch();
  const [errorState, setErrorState] = useState<ErrorState>({});

  useEffect(() => {
    if (props && props.sourceService && !errorState.sourceService) {
      // console.log("*****************");
      // console.log(props);
      // console.log(errorState.sourceService)
      const errorState: ErrorState = {
        // sourceService: props.sourceService,
        // errorCode: props.errorCode,
        // message: props.message,
        // inputParams: props.inputParams,
        // userMessage: props.userMessage,
        ...props,
      };
      setErrorState(errorState);
      dispatch(setError(errorState));
      throw new Error(JSON.stringify(errorState));
    }
  }, [props, errorState.sourceService, dispatch]);

  if (!props || !props.sourceService) {
    return false;
  }
  return true;
};
