import {
  GetTokenSilentlyOptions,
  RedirectLoginOptions,
  useAuth0,
} from "@auth0/auth0-react";
import { useRouter } from "next/router";
import { useCallback, useEffect, useState } from "react";
import { setAccessToken, setUpdateUser } from "../../services/auth0/slice";
import { client } from "../../services/graphql";
import {
  useGetUserQuery,
  useInsertUserMutation,
} from "../../services/graphql/enhanceApi";
import { useAppDispatch } from "../../services/store";
import { sourceServices } from "../error";
import { isDoneTutorial } from "../ui/onboarding";
import { path } from "../ui/route";
import { clearLogoutAtSendmail, isLogoutAtSendmail } from "../ui/sendMail";
import { useAuth0State } from "../../services/auth0/selectors";
import { useGetParamReferralCode } from "./useReferralCodeCheck";

export type GreenActionAuth0 = {
  isLoading: boolean; // Auth0ライブラリが初期化中の場合、true
  isError: boolean; // Auth0処理でエラーが発生した場合、true エラー内容の返却が必要になった場合は、要修正
  isAuthenticated: boolean; // 認証処理が完了している場合、true
  isAccessTokenReady: boolean; // Auth0認証処理が完了して、access_tokenの取得処理が完了している場合、true
  auth0UserId?: string; // Auth0でユーザーを識別するID
  userMetadata?: UserMetadata; // Auth0で保持しているユーザー情報
  userData?: UserData;
  logoutFunction: Function;
  updateAccessToken: () => Promise<void>;
  error?: Error;
};

export type UserMetadata = {
  inv?: string;
};

export type ConsentApplicationStatus =
  | "yet"
  | "processing"
  | "done"
  | "resultReceived"
  | "cancelByUser"
  | "cancelByTransfer";

export type UserData = {
  consentApplicationStatus: ConsentApplicationStatus;
  email?: string;
  consentApplicationZerocaStatus?: string | null;
  nickName: string;
  prefCode?: string | null;
};

const USER_METADATA_KEY = "https://zeroca.jp/user_metadata";
const AUTHORIZATION_HEADER = "Authorization";
const AUTHORIZATION_PREFIX = "Bearer ";

type AuthStatus =
  | "init" // 初期状態
  | "error" // 処理中にエラーが発生した
  | "isNotDoneTutorial" // チュートリアル未実施
  | "isNotDoneInitScore" // 初期スコア診断未完了
  | "gettingAccessToken" // アクセストークン取得中
  | "notLogin" // 未ログイン
  | "accessTokenReady" // アクセストークン取得済（認証完了）
  | "userInfoReady"; // ユーザー情報設定済

let addUserRecord = false; // usersテーブルへの登録を１回のみ実行するためのフラグ

export const useGreenActionAuth0 = (
  isAuth0CacheIgnore = false
): GreenActionAuth0 => {
  const {
    error,
    isLoading,
    isAuthenticated,
    getAccessTokenSilently,
    loginWithRedirect,
    user,
    logout,
  } = useAuth0();
  const dispatch = useAppDispatch();
  const { isRequiredCreatingAccount, isUpdateUser } = useAuth0State();
  const router = useRouter();
  const [
    { authStatus, accessToken, authError, userData, logoutAtSendmail },
    setAuthState,
  ] = useState<{
    authStatus: AuthStatus;
    accessToken?: string; // 各画面ごとにアクセストークンを更新するために、ローカルのstateに取得したアクセストークンを保持する
    authError?: Error;
    userData?: UserData;
    logoutAtSendmail?: boolean; // 未ログイン状態の場合、直前のログアウトがメール送信完了画面かを判定するフラグ
  }>({ authStatus: "init" });

  const [insertUser] = useInsertUserMutation();

  /**
   * 紹介コードパラメータの制御
   */
  const isCheckDone = useGetParamReferralCode();

  /**
   * アクセストークン取得関数
   */
  const updateAccessToken = useCallback(async () => {
    try {
      // console.log(await getIdTokenClaims());
      // アクセストークン取得リクエスト送信
      const options: GetTokenSilentlyOptions = isAuth0CacheIgnore
        ? {
            cacheMode: "off",
          }
        : {};
      const token = await getAccessTokenSilently(options);
      if (token !== accessToken) {
        // graphql向けにaccess_tokenを設定
        client.setHeader(
          AUTHORIZATION_HEADER,
          `${AUTHORIZATION_PREFIX}${token}`
        );
        // console.log("xxxxxxxxxxxxxxxxxxxxx" + token);
        // アプリ全体で参照するためにアクセストークンを保存
        dispatch(setAccessToken(token));
        setAuthState({ authStatus: "accessTokenReady", accessToken: token });
      }
    } catch (e) {
      console.error(e);
      if (isAuth0LoginRequired(e)) {
        setAuthState({
          authStatus: "notLogin",
          logoutAtSendmail: isLogoutAtSendmail(),
        });
      } else {
        const authError =
          e instanceof Error
            ? e
            : new Error(
                "認証処理でエラーが発生しました。再度ログインしてから処理を行ってください。"
              );
        setAuthState({ authStatus: "error", authError });
      }
    }
  }, [dispatch, getAccessTokenSilently, accessToken, isAuth0CacheIgnore]);

  // ユーザー情報取得
  const {
    data: getUserQuery,
    isSuccess,
    isError,
    error: apiError,
  } = useGetUserQuery(
    { auth0_user_id: user?.sub || "" },
    {
      skip: !accessToken,
      refetchOnMountOrArgChange: isUpdateUser,
    }
  );

  /**
   * 認証状態の管理
   */
  useEffect(() => {
    if (authStatus === "init" && error) {
      setAuthState({ authStatus: "error" });
    } else if (authStatus === "init" && !isLoading && isCheckDone) {
      if (!isDoneTutorial()) {
        // チュートリアル未実施
        //
        setAuthState({ authStatus: "isNotDoneTutorial" });
      } else {
        // チュートリアル実施済み
        // アクセストークン取得
        setAuthState({ authStatus: "gettingAccessToken" });
        (async () => {
          await updateAccessToken();
        })();
      }
    } else if (authStatus === "accessTokenReady" && isSuccess) {
      dispatch(setUpdateUser(false));
      if (user && user.sub && getUserQuery.zerocame_users[0]) {
        // ユーザー情報が保存されている
        if (getUserQuery.zerocame_users[0].greenscore_users.length === 1) {
          setAuthState({
            authStatus: "userInfoReady",
            accessToken,
            userData: {
              consentApplicationStatus: checkConsentApplicationStatus(
                getUserQuery.zerocame_users[0].consent_application_zeroca_status
              ),
              email: user.email || "",
              consentApplicationZerocaStatus:
                getUserQuery.zerocame_users[0]
                  .consent_application_zeroca_status !== undefined
                  ? getUserQuery.zerocame_users[0]
                      .consent_application_zeroca_status
                  : undefined,
              nickName:
                getUserQuery.zerocame_users[0].greenscore_users[0].nick_name,
              prefCode: getUserQuery.zerocame_users[0].pref_code,
            },
          });
        } else {
          // 初期スコア診断完了前
          setAuthState({
            authStatus: "isNotDoneInitScore",
            accessToken,
            userData: {
              consentApplicationStatus: checkConsentApplicationStatus(
                getUserQuery.zerocame_users[0].consent_application_zeroca_status
              ),
              consentApplicationZerocaStatus:
                getUserQuery.zerocame_users[0]
                  .consent_application_zeroca_status !== undefined
                  ? getUserQuery.zerocame_users[0]
                      .consent_application_zeroca_status
                  : undefined,
              nickName: "",
              prefCode: "",
            },
          });
        }
      } else if (user && user.sub) {
        // ユーザー情報未設定
        setAuthState({
          authStatus: "isNotDoneInitScore",
          accessToken,
          userData: undefined,
        });
        (async (auth0UserId: string) => {
          if (!addUserRecord) {
            addUserRecord = true;
            await insertUser({ auth0_user_id: auth0UserId });
          }
        })(user.sub);
      }
    }
  }, [
    error,
    isLoading,
    user,
    authStatus,
    accessToken,
    updateAccessToken,
    isSuccess,
    getUserQuery?.zerocame_users,
    dispatch,
    insertUser,
    isCheckDone,
  ]);

  if (authStatus === "init") {
    // Auth0ライブラリ初期化中
    return createResponse(
      isLoading,
      false,
      isAuthenticated,
      false,
      logout,
      updateAccessToken
    );
  } else if (authStatus === "error") {
    // Auth0ライブラリ処理でエラーが発生
    return createResponse(
      isLoading,
      true,
      isAuthenticated,
      false,
      logout,
      updateAccessToken,
      undefined,
      error ? error : authError
    );
  } else if (isError) {
    // ユーザー情報取得APIでエラー
    return createResponse(
      isLoading,
      true,
      isAuthenticated,
      false,
      logout,
      updateAccessToken,
      undefined,
      new Error(apiError.message)
    );
  }

  /**
   * 未ログインで、ログイン画面への遷移が発生する場合
   */
  if (authStatus === "isNotDoneTutorial") {
    console.log("isNotDoneTutorial");
    router.push(path.tutorial);
  } else if (authStatus === "isNotDoneInitScore") {
    console.log("isNotDoneInitScore", router.pathname);
    if (router.pathname !== path.greenscore.initScore)
      router.push(path.greenscore.initScore);
  } else if (authStatus === "notLogin") {
    console.log("notLogin");
    router.push(path.tutorial);
    // Auth0未ログイン状態
    // loginWithRedirect({});
    const loginOptions: RedirectLoginOptions =
      isRequiredCreatingAccount || logoutAtSendmail
        ? // ? { initialScreen: "signUp" }
          {
            authorizationParams: {
              screen_hint: "signUp",
            },
          }
        : {};
    // 直前のログアウトがメール送信完了画面であることを保持しているフラグをクリアする
    if (logoutAtSendmail) clearLogoutAtSendmail();
    router.push(path.tutorial);
  }

  const logoutFunction = () => {
    router.push(path.logout);
  };
  return createResponse(
    false, // isLoading
    false, // isError
    // authStatus === "userInfoReady",
    authStatus === "userInfoReady",
    !!accessToken,
    logoutFunction,
    updateAccessToken,
    user?.sub,
    undefined,
    userData,
    user && authStatus === "isNotDoneInitScore" && user[USER_METADATA_KEY]
      ? { inv: user[USER_METADATA_KEY]["inv"] }
      : undefined
  );
};

const createResponse = (
  isLoading: boolean,
  isError: boolean,
  isAuthenticated: boolean,
  isAccessTokenReady: boolean,
  logoutFunction: Function,
  updateAccessToken: () => Promise<void>,
  auth0UserId?: string,
  error?: Error,
  userData?: UserData,
  userMetadata?: UserMetadata
): GreenActionAuth0 => {
  return {
    isLoading,
    isError,
    isAuthenticated,
    isAccessTokenReady,
    logoutFunction,
    auth0UserId,
    updateAccessToken,
    error,
    userData,
    userMetadata,
  };
};

const auth0ErrorMessagesNotLogin = [
  "Login required",
  "External interaction required",
];
/**
 * getAccessTokenSilentlyで返却されたエラーが
 * ログインを要求するものか判定する
 * @param e
 * @returns ログインが要求されている場合、true
 */
const isAuth0LoginRequired = (e: unknown): boolean => {
  const isLoginRequired = auth0ErrorMessagesNotLogin.some(
    (errorMessage) =>
      (e instanceof Error && e.message.includes(errorMessage)) ||
      (typeof e === "string" && e.includes(errorMessage))
  );

  return isLoginRequired;
};

const checkConsentApplicationStatus = (
  consentApplicationStatus?: string | null
): ConsentApplicationStatus => {
  switch (consentApplicationStatus) {
    case "1":
      return "processing";
    case "2":
      return "done";
    case "3":
      return "resultReceived";
    case "4":
      return "cancelByUser";
    case "5":
      return "cancelByTransfer";
    default:
      return "yet";
  }
};
